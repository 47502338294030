import React, { useEffect, useCallback } from "react";
import { useStudioLiveContext } from "@vp/dragon";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { handleError, ERROR_CODES } from "@shared/utils/Errors";
import { useAppSelector, useAppDispatch, setSaveSuccess } from "@shared/redux";
import { studioLiveErrorCodes, topLevelStudioLiveErrorCodes, STUDIO_LIVE_ENTITY_CODE } from "@shared/utils/StudioLive";
import { DesignHelp } from "@shared/features/DesignHelp";
import { useIsStudioLiveEnabled } from "@five/utilities/useIsStudioLiveEnabled";
import { saveStudio5Design } from "../clients/saveClient";

const DesignHelpWrapper = ({ className }: { className?: string }) => {
    const dispatch = useAppDispatch();
    const switchingProject = useAppSelector(state => state.switchingProject);
    const isChangingTemplate = useAppSelector(state => state.isChangingTemplate);
    const { auth, identity } = useIdentityContext();
    const studioLiveEnabled = useIsStudioLiveEnabled();
    const { startSession, endSession, sessionStatus } = useStudioLiveContext();

    const handleHelpButtonClick = () => {
        if (studioLiveEnabled) {
            try {
                startSession();
            } catch (e) {
                const err = {
                    ...e,
                    errorMessage: `An error occurred while starting a studio live session: ${e.toString()}`,
                    errorCodeStack: `${STUDIO_LIVE_ENTITY_CODE}-${studioLiveErrorCodes.START_STUDIO_LIVE_SESSION}`
                };
                handleError(err, topLevelStudioLiveErrorCodes.START_STUDIO_LIVE);
            }
        }
    };

    const handleChatEnd = () => {
        try {
            endSession();
        } catch (e) {
            const err = {
                ...e,
                errorMessage: `An error occurred while ending a studio live session: ${e.toString()}`,
                errorCodeStack: `${STUDIO_LIVE_ENTITY_CODE}-${studioLiveErrorCodes.END_STUDIO_LIVE_SESSION}`
            };
            handleError(err, topLevelStudioLiveErrorCodes.END_STUDIO_LIVE);
        }
    };

    // if a user has an active session and switches their work from the my-projects panel
    // or changes template, we end their session
    useEffect(() => {
        if ((switchingProject || isChangingTemplate) && sessionStatus === "connected") {
            try {
                endSession();
            } catch (e) {
                const err = {
                    ...e,
                    errorMessage: `An error occurred while ending a studio live session: ${e.toString()}`,
                    errorCodeStack: `${STUDIO_LIVE_ENTITY_CODE}-${studioLiveErrorCodes.END_STUDIO_LIVE_SESSION}`
                };
                handleError(err, topLevelStudioLiveErrorCodes.END_STUDIO_LIVE);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [switchingProject, isChangingTemplate, sessionStatus]);

    const saveAndGetWorkId = useCallback(async () => {
        try {
            const content = await saveStudio5Design({ authToken: auth.getToken(), identity });

            return content.workId;
        } catch (e) {
            dispatch(setSaveSuccess({ saveSuccess: false }));
            handleError(e, ERROR_CODES.SAVE_DOCUMENT);
            throw e;
        }
    }, [auth, dispatch, identity]);

    return (
        <DesignHelp
            className={className}
            saveDesign={saveAndGetWorkId}
            onHelpButtonClick={handleHelpButtonClick}
            onChatEnd={handleChatEnd}
            startStudioLive={undefined}
            isStudioLiveEnabled={studioLiveEnabled}
        />
    );
};

DesignHelpWrapper.displayName = "DesignHelpWrapper";

export default DesignHelpWrapper;
