import React, { useEffect } from "react";
import classNames from "classnames";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import HelpAnywhereButton from "@vp/help-anywhere";
import { useTrackEvents } from "@shared/features/Tracking";
import { isCareAgent } from "@shared/utils/Care";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useAppSelector } from "@shared/redux";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import * as styles from "./DesignHelp.module.scss";

declare const window: Window &
    typeof globalThis & {
        liveDesignLink: string;
    };

interface DesignHelpProps {
    className?: string;
    /** Save design and return Work ID */
    saveDesign: () => Promise<string>;
    onHelpButtonClick?: () => void;
    onChatEnd?: () => void;
    startStudioLive?: () => void;
    isStudioLiveEnabled: boolean;
}
export function DesignHelp({
    className,
    saveDesign,
    onHelpButtonClick,
    onChatEnd,
    startStudioLive,
    isStudioLiveEnabled
}: DesignHelpProps) {
    const locale = useAppSelector(state => state.locale);
    const { trackEvent } = useTrackEvents();
    const { identity } = useIdentityContext();
    const productName = useAppSelector(state => state.productName);
    const productKey = useAppSelector(state => state.productKey);
    const idProduct = useAppSelector(state => state.mpvId);
    const workId = useAppSelector(state => state.workId);
    const { isSmall } = useStudioLayout();
    const coreProductversion = useAppSelector(state => state.productVersion);

    const handleHelpButtonClick = () => {
        trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_HELP_BUTTON });

        onHelpButtonClick?.();
    };

    const handleChatEnd = () => {
        onChatEnd?.();
    };

    const getPathFromUrl = (url: URL) => {
        return `${url.host}${url.pathname}`;
    };

    useEffect(() => {
        const generateLiveDesignLink = (workId: string) => {
            const ownerId = identity.shopperId || identity.anonymousUserId;
            window.liveDesignLink = `${getPathFromUrl(
                new URL(window.location.href)
            )}?workId=${workId}&careAgent=true&owner=${ownerId}`;
        };

        const dispatchLiveDesignLink = (liveDesignLink: string) => {
            const getLiveDesignURLLinkEvent = new CustomEvent("getLiveDesignURLLink", { detail: liveDesignLink });
            window.dispatchEvent(getLiveDesignURLLinkEvent);
        };

        const generateLiveDesignLinkEvent = () => {
            if (window.liveDesignLink) {
                dispatchLiveDesignLink(window.liveDesignLink);
                return;
            }
            if (workId) {
                generateLiveDesignLink(workId);
                dispatchLiveDesignLink(window.liveDesignLink);
            } else {
                saveDesign().then((generatedWorkId: string) => {
                    generateLiveDesignLink(generatedWorkId);
                    dispatchLiveDesignLink(window.liveDesignLink);
                });
            }
        };

        window.addEventListener("generateLiveDesignLinkEvent", generateLiveDesignLinkEvent);
        return () => {
            window.removeEventListener("generateLiveDesignLinkEvent", generateLiveDesignLinkEvent);
        };
    }, [identity.shopperId, identity.anonymousUserId, workId, saveDesign]);

    return (
        <>
            <div
                className={classNames(className, {
                    [styles.designHelpMobile]: isSmall,
                    [styles.designHelp]: !isSmall,
                    [styles.designHelpNormal]: !isSmall && !isStudioLiveEnabled
                })}
                data-testid="designHelp"
            >
                {!isCareAgent() ? (
                    <HelpAnywhereButton
                        locale={locale}
                        onClick={handleHelpButtonClick}
                        onChatEnd={handleChatEnd}
                        helpAnywhereOrigin="studioPages"
                        productDetails={{
                            productId: idProduct || "",
                            productName,
                            coreProductId: productKey,
                            requestor: "Studio",
                            coreProductVersion: coreProductversion || 0,
                            pageSection: "Studio",
                            pageStage: "Design"
                        }}
                    />
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}

DesignHelp.displayName = "DesignHelp";
